import Vue from "vue";
import accountConfig from "@/config/accountConfig.js";
// 付款原因
Vue.filter("paymentCourse", (val) => {
    const types = accountConfig.payCauses;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 付款方式
Vue.filter("paymentType", (val) => {
    const types = accountConfig.payTypes;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 付費狀態
Vue.filter("paymentStatus", (val) => {
    const types = accountConfig.payStatus;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 事件
Vue.filter("paymentReason", (val) => {
    const types = accountConfig.payReasons;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 會計對帳狀態
Vue.filter("accountStatus", (val) => {
    const types = accountConfig.accountStatus;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 會計對帳狀態文字
Vue.filter("accountStatusText", (val) => {
    const types = accountConfig.accountStatusText;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 訂單類型
Vue.filter("orderTypes", (val) => {
    const types = accountConfig.orderTypes;
    if (types[val]) {
        return types[val];
    }
    return val;
});

// 訂單交易類型收費百分比
Vue.filter("orderTypesPercentage", (val) => {
    const types = accountConfig.orderTypesPercentage;
    if (types[val]) {
        return types[val];
    }
    return val;
});
