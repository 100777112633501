<template>
    <transition
        enter-class="transform opacity-0 translate-y-0 transition duration-300"
        enter-to-class=" transform opacity-0 translate-y-0 transition duration-300"
        leave-to-class="transform opacity-0 transiton-y-0 transition duration-500">
        <div
            v-if="isLoading"
            :class="[isScrollDown ? 'bottom-0' : 'top-0', customClass]"
            class="w-full bg-opacity-50 items-center justify-center flex left-0 z-20 text-center">
            <div class="fixed top-1/2 mx-atuo w-full">
                <img class="w-10 h-10 mx-auto block animate-spin" src="/img/loading.svg" alt="" />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        // 判斷是否觸法 loading 動畫
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為滾動到最底部
        isScrollDown: {
            type: Boolean,
            default: false,
        },
        // 客製化 class
        customClass: {
            type: String,
            default: "bg-black fixed h-full",
        },
    },
};
</script>
