<template>
    <div class="my-pagination">
        <el-pagination
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="parseInt(paginationData.limit)"
            :pager-count="5"
            :layout="isMobile ? 'total, sizes, prev, next' : 'total, sizes, prev, pager, next, jumper'"
            :total="paginationData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
        <div class="total">共{{ paginationData.total }}筆資料</div>
    </div>
</template>

<script>
export default {
    props: {
        paginationData: {
            type: Object,
            default() {
                return {
                    limit: 15,
                    total: 0,
                };
            },
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            // 每頁比數選擇
            pageSizes: [1, 2, 10, 15, 50, 100],
        };
    },
    methods: {
        // 切換每頁顯示筆數
        handleSizeChange(val) {
            this.$emit("onPageSizeChange", val);
        },
        // 當分頁切換時觸發ajax 撈取新分頁資料
        handleCurrentChange(val) {
            this.$emit("onCurrentPageChange", val);
        },
    },
};
</script>

<style lang="scss" scoped>
.my-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .total {
        font-size: 18px;
        margin-left: 40px;
    }
}
.el-pagination {
    @apply flex items-center;
}
.el-pagination /deep/ .el-pagination__total {
    @screen sm {
        @apply hidden;
    }
    @apply flex;
}
.el-pagination /deep/ .el-pagination__sizes {
    @screen sm {
        @apply w-[100px];
    }
    @apply block w-full;
}
/deep/ .el-select {
    @apply w-full;
}
.el-pagination /deep/ .el-select .el-input {
    @apply w-full m-0;
}
/deep/ .el-input__inner {
    @apply h-[30px];
}
/deep/ .el-pagination__total {
    @apply text-black;
}
/deep/ .el-pager {
    @apply flex;
}
/deep/ .el-pager li {
    @apply bg-black bg-opacity-0 flex items-center justify-center;
    border: 2px solid theme("colors.black");
    height: calc(1.5em + 1.25rem + 2px);
    border-radius: 5px;
    margin-left: 5px;
}
/deep/ .el-pager li.active {
    background-color: theme("colors.black");
    @apply text-white border-none;
}
/deep/ .el-pager .number {
    color: theme("colors.black");
}
/deep/ .el-pager li.btn-quickprev {
    color: theme("colors.black");
}
/deep/ .el-pager li.btn-quicknext {
    color: theme("colors.black");
}
.el-pagination /deep/ .btn-prev {
    background-color: theme("colors.black");
    border-radius: 0.3rem;
    border: 2px solid theme("colors.black");
    height: calc(1.5em + 1.25rem + 2px);
    @apply p-[5px] ml-[10px] w-[50px] cursor-pointer text-white;
}
.el-pagination /deep/ .btn-next {
    background-color: theme("colors.black");
    border-radius: 0.3rem;
    border: 2px solid theme("colors.black");
    height: calc(1.5em + 1.25rem + 2px);
    @apply p-[5px] ml-[10px] w-[50px] cursor-pointer text-white;
}
.el-pagination /deep/ button:disabled {
    border: 2px solid theme("colors.gray.100");
    background-color: theme("colors.gray.100");
    height: calc(1.5em + 1.25rem + 2px);
    @apply text-white p-[5px] w-[50px] cursor-not-allowed;
}
/deep/ .el-input__inner {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid theme("colors.black");
    color: theme("colors.black");
}
/deep/ .el-select .el-input .el-select__caret {
    color: theme("colors.black");
}
/deep/ .el-pager li.active + li {
    border: 2px solid theme("colors.black");
}
</style>
