// 付款方式 （藍新提供）
const payTypes = {
    CREDIT: "信用卡",
    WEBATM: "WEBATM",
    VACC: "ATM轉帳",
    CVS: "超商代碼繳費",
    BARCODE: "超商條碼繳費",
    CVSCOM: "超商取貨付款",
    ALIPAY: "支付寶",
    P2GEACC: "ezPay 電子錢包",
    LINEPAY: "LINE Pay",
    ESUNWALLET: "玉山 Wallet",
    TAIWANPAY: "台灣 Pay",
};
// 付款原因
const payCauses = {
    "Prepare dating": "訂單預扣儲值",
    "Top up": "儲值",
    "Prepare dating extension": "續約預扣儲值",
};
// 付費狀態
const payStatus = {
    0: "尚未付款",
    1: "付款成功",
    "-1": "付款失敗",
    "-2": "退費成功",
};
// 付款事件
const payReasons = {
    WITHDRAW: "立即提領",
    CHARGE_OFF: "月結款項",
};

// 會計對帳金流狀態
const accountStatus = {
    0: "提出申請",
    1: "撥款處理",
    2: "已匯出TXT",
    3: "撥款完成",
    "-1": "標示異常",
};

// 會計對帳金流狀態文字
const accountStatusText = {
    SUBMITTED: "提出申請",
    REVIEWED: "撥款處理",
    EXPORTED: "已匯出TXT",
    COMPLETED: "撥款完成",
    ERROR: "標示異常",
};

// 訂單交易類型
const orderTypes = {
    SETTLE_DATING: "訂單",
    REJECT_DATING: "會員臨時取消",
};

// 訂單交易類型收費百分比
const orderTypesPercentage = {
    SETTLE_DATING: "70%",
    REJECT_DATING: "14%",
};

export default {
    payTypes,
    payCauses,
    payStatus,
    payReasons,
    accountStatus,
    accountStatusText,
    orderTypes,
    orderTypesPercentage,
};
