<template>
    <Dialog :showDialog="showDialogs" :customClass="'position'">
        <div class="pop-width md:p-10 px-5 py-3 bg-white rounded">
            <div class="content">
                <div class="title">
                    {{ param.title }}
                </div>
                <div class="des mb-3">{{ param.message }}</div>
                <el-date-picker
                    v-model="date"
                    value-format="yyyyMMdd"
                    size="small"
                    type="date"
                    :picker-options="pickerOptions"
                    placeholder="選擇匯款日期">
                </el-date-picker>
            </div>
            <div class="flex justify-center mt-3 border-gray-100 pt-2">
                <el-button class="black-btn-outline md:text-sm mr-2 text-xs w-[73px] h-[30px]" @click="cancel"> 取消 </el-button>
                <el-button :disabled="disable" class="orange-btn-800 md:text-sm text-xs w-[73px] h-[30px]" @click="confirm"> 確認 </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog.vue";

export default {
    name: "PopupInputDate",
    components: {
        Dialog,
    },
    props: {
        param: {
            type: Object,
            default() {
                return {
                    title: "",
                    message: "",
                };
            },
        },
        value: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        showDialogs: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            disable: true,
            date: "",
            pickerOptions: {
                //時間範圍
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                },
            },
        };
    },
    methods: {
        cancel() {
            this.showDialogs = false;
        },
        confirm() {
            this.showDialogs = false;
            this.$emit("confirm", this.date);
        },
    },
    watch: {
        date(val) {
            if (val) {
                this.disable = false;
            } else {
                this.disable = true;
            }
        },
    },
};
</script>

<style lang="scss">
.pop-width {
    max-width: 325px;
    margin: 0 162px 0 0;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            font-size: 16px;
        }
        .des {
            margin-top: 10px;
            font-size: 12px;
            color: #707070;
        }
    }
}
@media (min-width: 768px) {
    .position {
        top: calc(25% + 85px);
        left: calc(50% - 125px);
    }
}
</style>
